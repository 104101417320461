import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
} from '@mui/material';
import { PersonAdd, Edit, Delete } from '@mui/icons-material';
import FolderIcon from '@mui/icons-material/Folder';
import { styled } from '@mui/system';
import { keyframes } from '@emotion/react';
import { useNavigate } from 'react-router-dom'

import { createClient, deleteClient, getAllClients, handleTokenExpiration, updateClient } from '../../apiServices';

import './app.css';

const actions = [
  { icon: <PersonAdd />, name: 'Add Client' },
  // { icon: <Edit />, name: 'Edit Customer' },
];

const StyledContainer = styled(Container)({
  backgroundColor: '#f5f5f5',
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
});

const StyledPaper = styled(Paper)({
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  marginBottom: '20px',
  backgroundColor: '#ffffff',
});

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;


const CustomerBox = styled(Box)({
  padding: '10px',
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  marginBottom: '10px',
  backgroundColor: '#e3f2fd',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const AdminDashboard = () => {

  const navigate = useNavigate();

  const [showWelcome, setShowWelcome] = useState(true);
  const [openForm, setOpenForm] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [customerData, setCustomerData] = useState({
    email: '',
    password: '',
    mobile: '',
    firstName: '',
    middleName: '',
    lastName: '',
    userName: '',
    projectName: '',
  });

  const [customerEditData, setCustomerEditData] = useState({
    email: '',
    mobile: '',
    firstName: '',
    middleName: '',
    lastName: '',
    userName: '',
  })
  const [customers, setCustomers] = useState([
    // {
    //   userName: "userName",
    //   firstName: 'John',
    //   lastName: 'Doe',
    //   email: 'john.doe@example.com',
    //   projectName: 'New Project'
    // },
    // {
    //   userName: "userName",
    //   firstName: 'Jane',
    //   lastName: 'Smith',
    //   email: 'jane.smith@example.com',
    //   projectName: 'New Project'
    // },
    // {
    //   userName: "userName",
    //   firstName: 'Alice',
    //   lastName: 'Johnson',
    //   email: 'alice.johnson@example.com',
    //   projectName: 'New Project'
    // },
    // {
    //   userName: "userName",
    //   firstName: 'Bob',
    //   lastName: 'Brown',
    //   email: 'bob.brown@example.com',
    //   projectName: 'New Project'
    // },
    // {
    //   userName: "userName",
    //   firstName: 'Charlie',
    //   lastName: 'Davis',
    //   email: 'charlie.davis@example.com',
    //   projectName: 'New Project'
    // }
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowWelcome(false);
    }, 120000); // Hide welcome message after 2 minutes (120000 milliseconds)
    return () => clearTimeout(timer);
  }, []);


  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const admin = localStorage.getItem('admin');
        if (!accessToken || !admin) {
          alert('Login required');
          window.location.href = '/'; // Redirect to the login page
        } else {
          await handleTokenExpiration();
          const response = await getAllClients();
          console.log(response)
          if (response.status === 200) {
            setCustomers(response.data);
          } else {
            alert('Error fetching customers');
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchCustomers();
  }, []);

  const handleSpeedDialClick = (actionName) => {
    if (actionName === 'Add Client') {
      setOpenForm(true);
    } else if (actionName === 'Edit Customer') {
      // Handle edit customer logic here
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const {
      email,
      password,
      mobile,
      firstName,
      middleName,
      lastName,
      userName,
      projectName,
    } = customerData;

    try {
      const accessToken = localStorage.getItem('accessToken');
      const admin = localStorage.getItem('admin');
      if (!accessToken || !admin) {
        alert('Login required');
        window.location.href = '/'; // Redirect to the login page
      } else {
        await handleTokenExpiration();
        const response = await createClient(email, password, mobile, firstName, middleName, lastName, userName, projectName);
        if (response.status === 201) {
          alert('Customer added successfully');
          const res = await getAllClients()
          setCustomers(res.data);
        } else if (response.status === 400) {
          alert(`Error adding customer: ${response.data.message}`);
        } else {
          alert('Error adding customer');
        }
      }
    } catch (err) {
      console.log(err);
    }

    setOpenForm(false);
    setCustomerData({
      email: '',
      password: '',
      mobile: '',
      firstName: '',
      middleName: '',
      lastName: '',
      userName: '',
      projectName: '',
    });
  };

  const handleEditFormSubmit = async (e) => {
    e.preventDefault();
    const {
      email,
      mobile,
      firstName,
      middleName,
      lastName,
      userName,
    } = customerEditData;

    try {
      const accessToken = localStorage.getItem('accessToken');
      const admin = localStorage.getItem('admin');
      if (!accessToken || !admin) {
        alert('Login required');
        window.location.href = '/'; // Redirect to the login page
      } else {
        await handleTokenExpiration();
        const response = await updateClient(email, mobile, firstName, middleName, lastName, userName);
        if (response.status === 200) {
          const res = await getAllClients()
          setCustomers(res.data);
          alert('customer updated successfully');
        } else if (response.status === 400) {
          alert(`Error updating customer: ${response.data.message}`);
        } else {
          alert('Error updating customer');
        }
      }
    } catch (err) {
      console.log(err);
    }

    setOpenEditForm(false);
    setCustomerEditData({
      email: '',
      mobile: '',
      firstName: '',
      middleName: '',
      lastName: '',
      userName: '',
    });
  };

  const handleDeleteCustomer = async (customerIndex) => {
    const customerToDelete = customers[customerIndex];
    console.log(customerToDelete)
    try {
      const accessToken = localStorage.getItem('accessToken');
      const admin = localStorage.getItem('admin');
      if (!accessToken || !admin) {
        alert('Login required');
        window.location.href = '/'; // Redirect to the login page
      } else {
        await handleTokenExpiration();
        const response = await deleteClient(customerToDelete.userName)
        if (response.status === 200) {
          alert('Customer deleted successfully');
          const response = await getAllClients()
          setCustomers(response.data);
        } else {
          alert('Error deleting customer');
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleEditCustomer = (customerIndex) => {
    const customerToEdit = customers[customerIndex];
    setCustomerEditData({
      email: customerToEdit.email,
      mobile: customerToEdit.mobile,
      firstName: customerToEdit.firstName,
      middleName: customerToEdit.middleName,
      lastName: customerToEdit.lastName,
      userName: customerToEdit.userName,
    });
    setOpenEditForm(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('admin');
    window.location.href = '/'; // Redirect to the login page
  };


  const handleCustomerClick = (customer) => {
    let path = '/clientdashboard';
    if (customer) {
      path += `/${customer.userName}`;
    }
    navigate(path);
  };

  return (
    <StyledContainer>
      {/* First Container */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1} >
        <div className='logo-wrapper'>
          <img
            src='./logo.png'
            alt="Company Logo"
            style={{ height: "65px", marginRight: "20px" }}
          />
        </div> {/* Adjust the path to where your logo is stored */}
        <Typography variant="h4" color="primary">Admin Panel</Typography>
        <div  className='logout-container'>
          <div onClick={handleLogout} className='logout-wrapper'><svg xmlns="http://www.w3.org/2000/svg" width={14} viewBox="0 0 512 512"><path fill="#fff" d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"/></svg></div>
          <div>logout</div>
        </div>      
      </Box>

      {/* Second Container */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        {showWelcome && (
          <StyledPaper
            elevation={3}
            sx={{ flexGrow: 1, backgroundColor: '#e3f2fd', animation: `${fadeOut} 2s linear forwards` }}
          >
            <Typography variant="h6">Hey Admin!</Typography>
          </StyledPaper>
        )}
        {/* <Box onClick={handleNotepadClick} sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
          <NoteAdd color="primary" />
          <Typography variant="button" color="primary" sx={{ marginLeft: '8px' }}>Notepad</Typography>
        </Box> */}
      </Box>
      {/* {showNotepad && (
        <StyledPaper elevation={3}>
          <Typography variant="h6" color="primary">Notepad</Typography>
          <textarea
            value={notepadContent}
            onChange={(e) => setNotepadContent(e.target.value)}
            rows={5}
            style={{ width: '100%', padding: '10px', borderRadius: '4px', borderColor: '#cfd8dc' }}
          />
          <Button onClick={handleNotepadSave} color="primary" variant="contained" sx={{ marginTop: '10px' }}>Save</Button>
        </StyledPaper>
      )} */}

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StyledPaper elevation={3}>
            <Typography variant="h6" color="primary">Customer List</Typography>
            <List>
              {customers && (customers.length > 0) && customers.map((customer, index) => (
                <CustomerBox key={index}>
                  <ListItem onClick={() => handleCustomerClick(customer)}>
                    <ListItemAvatar>
                      <Avatar>
                        <FolderIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${customer.firstName} ${customer.middleName} ${customer.lastName}`}
                      secondary={customer.projectName}
                    >
                      {/* <div className='project-name'>{customer.projectName}nadminadminadminadminadminadminadmin</div> */}
                    </ListItemText>
                  </ListItem>
                  <IconButton edge="end" aria-label="delete" onClick={() => handleEditCustomer(index)}>
                    <Edit />
                  </IconButton>
                  <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteCustomer(index)}>
                    <Delete />
                  </IconButton>
                </CustomerBox>
              ))}
            </List>
          </StyledPaper>
        </Grid>
      </Grid>

      {/* SpeedDial for actions */}
      <SpeedDial
        ariaLabel="SpeedDial example"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => handleSpeedDialClick(action.name)}
          />
        ))}
      </SpeedDial>

      {/* Dialog for adding customer */}
      <Dialog open={openForm} onClose={() => setOpenForm(false)}>
        <DialogTitle>Add Customer</DialogTitle>
        <DialogContent>
          <TextField
            label="Email"
            value={customerData.email}
            onChange={(e) => setCustomerData({ ...customerData, email: e.target.value })}
            fullWidth
            required
            margin="normal"
          />
          <TextField
            label="Password"
            value={customerData.password}
            onChange={(e) => setCustomerData({ ...customerData, password: e.target.value })}
            fullWidth
            type="password"
            required
            margin="normal"
          />
          <TextField
            label="Mobile"
            value={customerData.mobile}
            onChange={(e) => setCustomerData({ ...customerData, mobile: e.target.value })}
            fullWidth
            required
            margin="normal"
          />
          <TextField
            label="First Name"
            value={customerData.firstName}
            onChange={(e) => setCustomerData({ ...customerData, firstName: e.target.value })}
            fullWidth
            required
            margin="normal"
          />
          <TextField
            label="Middle Name"
            value={customerData.middleName}
            onChange={(e) => setCustomerData({ ...customerData, middleName: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Last Name"
            value={customerData.lastName}
            onChange={(e) => setCustomerData({ ...customerData, lastName: e.target.value })}
            fullWidth
            required
            margin="normal"
          />
          <TextField
            label="Username"
            value={customerData.userName}
            onChange={(e) => setCustomerData({ ...customerData, userName: e.target.value })}
            fullWidth
            required
            margin="normal"
          />
          <TextField
            label="Project Name"
            value={customerData.projectName}
            onChange={(e) => setCustomerData({ ...customerData, projectName: e.target.value })}
            fullWidth
            required
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenForm(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleFormSubmit} color="primary">
            {/* {selectedCustomer ? 'Save Changes' : 'Add Customer'} */}
            'Add Customer'
          </Button>
        </DialogActions>
      </Dialog>


      {/* Dialog for editing customer */}
      <Dialog open={openEditForm} onClose={() => setOpenEditForm(false)}>
        <DialogTitle>Edit Customer</DialogTitle>
        <DialogContent>
          <TextField
            label="Email"
            value={customerEditData.email}
            onChange={(e) => setCustomerEditData({ ...customerEditData, email: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Mobile"
            value={customerEditData.mobile}
            onChange={(e) => setCustomerEditData({ ...customerEditData, mobile: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="First Name"
            value={customerEditData.firstName}
            onChange={(e) => setCustomerEditData({ ...customerEditData, firstName: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Middle Name"
            value={customerEditData.middleName}
            onChange={(e) => setCustomerEditData({ ...customerEditData, middleName: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Last Name"
            value={customerEditData.lastName}
            onChange={(e) => setCustomerEditData({ ...customerEditData, lastName: e.target.value })}
            fullWidth
            margin="normal"
          />
          {/* <TextField
            label="Username"
            value={customerEditData.userName}
            onChange={(e) => setCustomerEditData({ ...customerEditData, userName: e.target.value })}
            fullWidth
            margin="normal"
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditForm(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEditFormSubmit} color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for success message */}
      {/*<Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          Customer added successfully!
        </Alert>
      </Snackbar>*/}
    </StyledContainer>
  );
};

export default AdminDashboard;
