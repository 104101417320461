import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css"; // Assuming you have some global styles here
import AdminDashboard from "./components/admin/admin"; // Adjust the path if necessary
import Auth from "./pages/auth/auth";
import ClientDashboard from "./components/client/client";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Auth />} />
          <Route path="/admindashboard" element={<AdminDashboard />} />
          <Route path="/clientdashboard/:userName?" element={<ClientDashboard />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;


