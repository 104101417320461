import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

// import dotenv from 'dotenv'

// dotenv.config()

const BASE_URL = 'https://jiana-backend-80857222155.asia-south1.run.app'

const adminLogin = async (email, password) => {
    const url = `${BASE_URL}/admin/login`;
    const data = {
        email: email,
        password: password
    };

    try {
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.status === 200) {
            const authHeader = response.headers['authorization'];
            const refreshHeader = response.headers['refresh-token'];

            if (authHeader && refreshHeader) {
                const accessToken = authHeader.split(' ')[1];
                const refreshToken = refreshHeader;

                console.log('Login successful:', response.data);
                console.log('Access Token:', accessToken);
                console.log('Refresh Token:', refreshToken);
                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('refreshToken', refreshToken);
                localStorage.setItem('admin', 'admin');
            }
        }

        return response
    } catch (error) {
        if (error.response) {
            console.error('Error response:', error.response.data);
        } else {
            console.error('Error message:', error.message);
        }
        alert('Invalid email or password');
    }
};

const createClient = async (email, password, mobile, firstName, middleName, lastName, userName, projectName) => {
    const url = `${BASE_URL}/admin/createclient`
    const accessToken = localStorage.getItem('accessToken');

    const data = {
        email: email,
        password: password,
        mobile: mobile,
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
        userName: userName,
        projectName: projectName
    };

    try {
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        });
        console.log(response.data)
        if (response.status === 201) {
            console.log('Client created successfully:', response.data);
        }

        return response
    } catch (error) {
        if (error.response) {
            console.error('Error response:', error.response.data);
        } else {
            console.error('Error message:', error.message);
        }
        return error.response
    }
};

// Call the create client function

const getAllClients = async () => {
    const url = `${BASE_URL}/admin/getallclient`;
    const accessToken = localStorage.getItem('accessToken');

    try {
        const response = await axios.get(url, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            console.log('Clients retrieved successfully:', response.data);
        }
        return response
    } catch (error) {
        if (error.response) {
            console.error('Error response:', error.response.data);
        } else {
            console.error('Error message:', error.message);
        }
        return error.response
    }
};


const updateClient = async (email, mobile, firstName, middleName, lastName, userName) => {
    const url = `${BASE_URL}/admin/updateclient`;
    const accessToken = localStorage.getItem('accessToken');

    const data = {
        email: email,
        mobile: mobile,
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
        userName: userName
    };

    try {
        const response = await axios.put(url, data, {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });

        if(response.status === 200) {
            console.log('Client updated successfully:', response.data);
        }
        
        return response
    } catch (error) {
        if (error.response) {
            console.error('Error response:', error.response.data);
        } else {
            console.error('Error message:', error.message);
        }
        return error.response
    }
};

const deleteClient = async (userName) => {
    const url = `${BASE_URL}/admin/deleteclient?userName=${userName}`;
    const accessToken = localStorage.getItem('accessToken');

    try {
        const response = await axios.delete(url, {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });

        if(response.status === 200) {
            console.log('Client deleted successfully:', response.data);
        }
        console.log(response.data)
        return response
    }catch (error) {
        if (error.response) {
            console.error('Error response:', error.response.data);
        } else {
            console.error('Error message:', error.message);
        }
        return error.response
    }
}

const refreshToken = async () => {
    const url = `${BASE_URL}/admin/refreshtoken`;
    const refreshToken = localStorage.getItem('refreshToken'); // Get the refresh token from local storage

    try {
        const response = await axios.post(url, {}, {
            headers: {
                'Refresh-Token': refreshToken
            }
        });

        if (response.status === 200) {
            const authHeader = response.headers['authorization'];

            if (authHeader) {
                const accessToken = authHeader.split(' ')[1];

                console.log('Token refreshed successfully:', response.data);
                console.log('Access Token:', accessToken);
                localStorage.setItem('accessToken', accessToken);
            }
        }
    } catch (error) {
        if (error.response) {
            console.error('Error response:', error.response.data);
        } else {
            console.error('Error message:', error.message);
        }
        return error.response
    }
};


const clientLogin = async (email, password) => {
    const url = `${BASE_URL}/client/login`;

    const data = {
        email: email,
        password: password
    };

    try {
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.status === 200) {
            const authHeader = response.headers['authorization'];
            const refreshHeader = response.headers['refresh-token'];

            if (authHeader && refreshHeader) {
                const accessToken = authHeader.split(' ')[1];
                const refreshToken = refreshHeader;
                const userName = response.data.userName;

                console.log('Login successful:', response.data);
                console.log('Access Token:', accessToken);
                console.log('Refresh Token:', refreshToken);
                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('refreshToken', refreshToken);
                localStorage.setItem('client', userName);
                
            }
        }

        return response
    } catch (error) {
        if (error.response) {
            console.error('Error response:', error.response.data);
        } else {
            console.error('Error message:', error.message);
        }
        alert('Invalid email or password');
    }
};


const fetchProject = async (client) => {
    const url = `${BASE_URL}/project/fetch?client=${client}`;
    const accessToken = localStorage.getItem('accessToken');

    try {
        const response = await axios.get(url, {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });

        if(response.status === 200) {
            console.log('Project fetched successfully:', response.data);
        }
        return response
    } catch (error) {
        if (error.response) {
            console.error('Error response:', error.response.data);
        } else {
            console.error('Error message:', error.message);
        }
        return error.response
    }
};


const updateProject = async (client, imageUrl, projectName, progress) => {
    const url = `${BASE_URL}/project/update`;
    const accessToken = localStorage.getItem('accessToken');

    const data = {
        client: client,
        imageUrl: imageUrl,
        projectName: projectName,
        progress: progress
    };

    try {
        const response = await axios.put(url, data, {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });

        if(response.status === 200) {
            console.log('Client updated successfully:', response.data);
        }
        
        return response
    } catch (error) {
        if (error.response) {
            console.error('Error response:', error.response.data);
        } else {
            console.error('Error message:', error.message);
        }
        return error.response
    }
};


const isAccessTokenExpired = () => {
    const accessToken = localStorage.getItem('accessToken');
    // Decode the access token
    const decodedAccessToken = jwtDecode(accessToken);
    // Get the expiry time
    const expiryTime = decodedAccessToken.exp;
    // Get the current time
    const currentTime = Date.now() / 1000;
    // Check if the access token has expired
    if (expiryTime < currentTime) {
        return true;
    } else {
        return false;
    }
}

const handleTokenExpiration = async () => {
    const isExpired = isAccessTokenExpired();
    if (isExpired) {
        await refreshToken();
    }
}

export { adminLogin, createClient, getAllClients, updateClient, deleteClient, refreshToken, clientLogin, fetchProject, updateProject, BASE_URL, handleTokenExpiration };