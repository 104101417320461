import React from 'react'
import "./auth.css"
import { adminLogin, clientLogin } from '../../apiServices';

const Auth = () => {

	const [email, setEmail] = React.useState("");
	const [password, setPassword] = React.useState("");

	const handleEmailChange = (e) => {
		setEmail(e.target.value);
	}

	const handlePasswordChange = (e) => {
		setPassword(e.target.value);
	}

	const handleAdminLogin = async (e) => {
		e.preventDefault();
		try {
			const response = await adminLogin(email, password);
			if (response.status === 200) {
				alert('Login successful');
				window.location.href = '/admindashboard';
			} else {
				alert('Invalid email or password');
			}
		} catch (err) {
			console.log(err)
		}
	}

	const handleClientLogin = async (e) => {
		e.preventDefault();
		try {
			const response = await clientLogin(email, password);
			if (response.status === 200) {
				alert('Login successful');
				window.location.href = '/clientdashboard';
			} else {
				alert('Invalid email or password');
			}
		} catch (err) {
			console.log()
		}
	}

	return (
		<div className='main-wrapper'>
			<div className="main">
				<input type="checkbox" id="chk" aria-hidden="true" />

				<div className="signup">
					<form>
						<label htmlFor="chk" aria-hidden="true">Login Customer</label>
						<input
							onChange={handleEmailChange}
							type="email" name="email" placeholder="Email" required />
						<input
							onChange={handlePasswordChange}
							type="password" name="pswd" placeholder="Password" required />
						<button onClick={handleClientLogin}>Login</button>
					</form>
				</div>

				<div className="login">
					<form>
						<label htmlFor="chk" aria-hidden="true" style={{fontSize: "30px", paddingTop: "24px"}}>Login Admin</label>
						<input
							onChange={handleEmailChange}
							type="email" name="email" placeholder="Email" required />
						<input
							onChange={handlePasswordChange}
							type="password" name="pswd" placeholder="Password" required />
						<button onClick={handleAdminLogin}>Login</button>
					</form>
				</div>
			</div>
		</div>
	)
}

export default Auth
