import React, { useState, useEffect } from "react";
import {
    Container,
    Box,
    Typography,
    Avatar,
    IconButton,
    Button,
    TextField,
    ImageList,
    ImageListItem,
    Tooltip,
    Grid,
} from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import EditIcon from "@mui/icons-material/Edit";
import CustomPieChart from "./PieChart";
import logo from "./logo.png";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { fetchProject, handleTokenExpiration, updateProject } from "../../apiServices";
import { useParams } from 'react-router-dom';

import './../admin/app.css';

const ClientDashboard = () => {
    const { userName } = useParams();
    const [projectName, setProjectName] = useState("");
    const [clientName, setClientName] = useState("");
    const [imagesFetched, setImagesFetched] = useState([]);
    const [imageUrl, setImageUrl] = useState(null);
    const [editPieChart, setEditPieChart] = useState(false);
    const [pieData, setPieData] = useState([
        { name: "Work Left", value: 100, color: "yellow" },
        { name: "Completed", value: 0, color: "blue" },
    ]);

    const handlePieChartEdit = () => setEditPieChart(!editPieChart);

    const handlePieDataChange = (index, value) => {
        if (value < 1) value = 1;
        if (value > 100) value = 100;
        const newPieData = [...pieData];
        newPieData[index].value = parseInt(value, 10);
        setPieData(newPieData);
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setImageUrl(reader.result);
        };

        reader.readAsDataURL(file);
    };

    const generateReport = async () => {
        try {
            const doc = new jsPDF();
            // Add title and date
            doc.addImage(logo, "PNG", 50, 100, 100, 55);
            doc.text("Customer Dashboard Report", 70, 170);
            const currentDate = new Date().toLocaleDateString();
            doc.text(`Date: ${currentDate}`, 80, 190);

            doc.addPage();
            // Function to calculate the aspect ratio and return dimensions
            const getImageDimensions = (img, maxWidth, maxHeight) => {
                const ratio = Math.min(maxWidth / img.width, maxHeight / img.height);
                return { width: img.width * ratio, height: img.height * ratio };
            };

            for (let i = 0; i < imagesFetched.length; i++) {
                const img = new Image();
                img.src = imagesFetched[i];

                await new Promise((resolve) => {
                    img.onload = () => {
                        const { width, height } = getImageDimensions(img, 190, 150);
                        if (i > 0) doc.addPage();
                        doc.addImage(img, "JPEG", 10, 10, width, height);
                        resolve();
                    };
                });
            }

            doc.save("report.pdf");
        } catch (err) {
            console.log(err);
        }
    };


    const setDataFromDB = (data) => {
        setProjectName(data.projectName);
        handlePieChartEdit(true);
        setPieData([
            { name: "Work Left", value: 100 - data.status[data.status.length - 1].progress, color: "yellow" },
            { name: "Completed", value: data.status[data.status.length - 1].progress, color: "blue" },
        ]);
        handlePieChartEdit(false);
        let allImages = [];
        data.status.forEach(status => {
            if (status.file) {
                allImages.push(status.file.fileUrl);
            } else {
                console.log("no files present");
            }
        });
        setImagesFetched(allImages);
    };

    useEffect(() => {
        const fetchClientProject = async () => {
            try {
                const accessToken = localStorage.getItem('accessToken');
                const client = localStorage.getItem('client');
                const admin = localStorage.getItem('admin')

                if (admin && accessToken) {
                    await handleTokenExpiration();
                    setClientName(userName);
                    const response = await fetchProject(userName);
                    const data = response.data.project;
                    if (response.status === 200) {
                        setDataFromDB(data)
                    } else {
                        alert('Error fetching customer project');
                    }
                } else if (!accessToken || !client) {
                    alert('Login required');
                    window.location.href = '/'; // Redirect to the login page
                } else {
                    await handleTokenExpiration();
                    setClientName(client)
                    const response = await fetchProject(client);
                    const data = response.data.project;
                    console.log(data)
                    if (response.status === 200) {
                        setDataFromDB(data)
                    } else {
                        alert('Error fetching customer project');
                    }
                }
            } catch (err) {
                console.log(err);
            }
        }
        fetchClientProject()
    }, []);

    const handleUpdateStatus = async (e) => {
        e.preventDefault();
        try {
            const accessToken = localStorage.getItem('accessToken');
            const admin = localStorage.getItem('admin');
            if (!accessToken || !admin) {
                alert('Login required');
                window.location.href = '/'; // Redirect to the login page
            } else {
                await handleTokenExpiration();
                const response = await updateProject(userName, imageUrl, projectName, pieData[1].value);
                if (response.status === 200) {
                    const response = await fetchProject(userName);
                    const data = response.data.project;
                    console.log(data);
                    alert("Project updated successfully");
                    if (response.status === 200) {
                        setDataFromDB(data);
                        setImageUrl(null);
                    } else {
                        alert('Error fetching project');
                    }
                } else {
                    alert('Error updating project status');
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleBackButton = () => {
        window.location.href = '/admindashboard';
    }

    const handleLogout = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('admin');
        window.location.href = '/'; // Redirect to the login page
      };

    return (
        <Container sx={{ bgcolor: "#fff", color: "#1c2d37", p: 2 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1} >
                <Box
                    sx={{
                        bgcolor: "#fff",
                        p: 2,
                        mb: 2,
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        color: "#1c2d37",
                    }}
                >
                    {localStorage.getItem('admin') && (<div onClick={handleBackButton} style={{ marginRight: '30px', width: "20px", cursor: "pointer" }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#555" d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg></div>)}
                    <img
                        onClick={handleBackButton}
                        src={logo}
                        alt="Company Logo"
                        style={{ height: "50px", marginRight: "20px", cursor: "pointer" }}
                    />
                    <Typography variant="h4" component="div">
                        Customer Dashboard
                    </Typography>
                </Box>
                <div className='logout-container'>
                    <div onClick={handleLogout} className='logout-wrapper' style={{backgroundColor: "#ff5722"}}><svg xmlns="http://www.w3.org/2000/svg" width={14} viewBox="0 0 512 512"><path fill="#fff" d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z" /></svg></div>
                    <div>logout</div>
                </div>
            </Box>


            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    bgcolor: "#fff",
                    p: 2,
                    mb: 2,
                    justifyContent: "space-between",
                    color: "#1c2d37",
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Avatar sx={{ bgcolor: deepOrange[500] }}>{clientName.charAt(0)}</Avatar>
                    <Typography variant="h6" component="div" sx={{ ml: 2 }}>
                        {clientName}
                    </Typography>
                </Box>
            </Box>

            <Typography variant="h5" component="div">Project Name: {projectName}</Typography>
            <Grid container spacing={2} sx={{ bgcolor: "#fff", p: 2, mb: 2, color: "#1c2d37" }}>
                <Grid item xs={12} md={6}>
                    <CustomPieChart key={JSON.stringify(pieData)} data={pieData} />
                    {localStorage.getItem('admin') && (
                        <Box sx={{ textAlign: "center", mt: 2 }}>
                            <Tooltip title="Edit Pie Chart">
                                <IconButton
                                    sx={{ color: "#1c2d37" }}
                                    onClick={handlePieChartEdit}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                    {localStorage.getItem('admin') && editPieChart && (
                        <Box sx={{ mt: 2 }}>
                            {pieData.map((data, index) => (
                                <Box
                                    key={index}
                                    sx={{ display: "flex", alignItems: "center", mb: 1 }}
                                >
                                    <Typography sx={{ mr: 2 }}>{data.name}:</Typography>
                                    <TextField
                                        type="number"
                                        variant="outlined"
                                        size="small"
                                        value={data.value}
                                        onChange={(e) => handlePieDataChange(index, e.target.value)}
                                        inputProps={{ min: 1, max: 100 }}
                                    />
                                </Box>
                            ))}
                        </Box>
                    )}
                </Grid>
                <Grid item xs={12} md={6}>
                    {localStorage.getItem('admin') && (
                        <>
                            <Typography variant="h5" component="div">Files</Typography>
                            <Button
                                variant="contained"
                                component="label"
                                sx={{
                                    bgcolor: "#1c2d37",
                                    "&:hover": { bgcolor: "#1c2d37" },
                                    color: "#fff",
                                    mt: 2,
                                }}
                            >
                                Upload Files
                                <input
                                    type="file"
                                    accept=".jpeg, .png, .jpg, .webp"
                                    hidden
                                    onChange={handleImageUpload}
                                />
                            </Button>
                            <div style={{ marginTop: "10px" }}>Only jpeg, png, jpg, files are allowed</div>
                            <br />
                        </>
                    )}
                    <ImageList
                        sx={{ width: "100%", height: 300, mt: 2 }}
                        cols={3}
                        rowHeight={100}
                    >
                        {imagesFetched.map((image, index) => (
                            <ImageListItem key={index}>
                                <img src={image} alt={image} />
                            </ImageListItem>
                        ))}
                        {imageUrl && <img src={imageUrl} alt="Preview" style={{ width: '150px' }} />}
                    </ImageList>
                </Grid>
            </Grid>
            {localStorage.getItem('admin') && (
                <Box sx={{ textAlign: "center", mt: 2, mb: 5 }}>
                    <Button
                        variant="contained"
                        onClick={handleUpdateStatus}
                        sx={{
                            bgcolor: "#1c2d37",
                            "&:hover": { bgcolor: "#1c2d37" },
                            color: "#fff",
                        }}
                    >
                        Update Status
                    </Button>
                </Box>
            )}
            <Box sx={{ textAlign: "center", mt: 2, mb: 2 }}>
                <Button
                    variant="contained"
                    onClick={generateReport}
                    sx={{
                        bgcolor: "#1c2d37",
                        "&:hover": { bgcolor: "#1c2d37" },
                        color: "#fff",
                    }}
                >
                    Download Report
                </Button>
            </Box>
        </Container>
    );
};

export default ClientDashboard;
